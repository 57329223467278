import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// components
import Layout from '@src/layouts';
// import Cta from '@components/molecules/Cta';
import FormMessage from '@components/molecules/FormMessage';
import HeroPage from '@components/molecules/heros/HeroPage';
import ListProjects from '@components/organisms/ListProjects';

// assets
// import gifCrowsnestDesktop from '@assets/images/projects/project_Crowsnest-oblong.gif';
import gifCrowsnestMobile from '@assets/images/projects/project_Crowsnest-large.gif';
import gifEtjf from '@assets/images/projects/thumbs/PORTRAIT-ETVJ.gif';
import imgTree from '@assets/images/tree.svg';
import gifFwt from '@assets/images/projects/thumbs/SQUARE-FWT.gif';

// --------------------------------
// #region Data
// --------------------------------

const pageClass = 'page-projects';

const language = 'en';

const footerRelatedLinks = [
	{
		title: 'Agency',
		url: '/en/agency/',
	},
	{ title: 'Team', url: '/en/team/' },
];

// --------------------------------
// #endregion
// --------------------------------

const ProjectsPage = (props) => {
	const data = useStaticQuery(
		graphql`
			query {
				projectImages: allFile(
					filter: {
						relativePath: {
							in: [
								"projects/thumbs/SQUARE-Ackermann.jpg"
								"projects/thumbs/PORTRAIT-Vidy.jpg"
								"projects/thumbs/SQUARE-MJF-Site.jpg"
								"projects/thumbs/SQUARE-LesChauffeurs.jpg"
								"projects/thumbs/PORTRAIT-TDH2022.jpg"
								"projects/thumbs/SQUARE-RachisClinic.jpg"
								"projects/thumbs/SQUARE-MJF-Swipe.jpg"
								"projects/thumbs/PORTRAIT-Avrion.jpg"
								"projects/thumbs/PORTRAIT-Debiopharm.jpg"
								"projects/Illustre-SQUARE.jpg"
								"projects/thumbs/SQUARE-ECA.jpg"
								"projects/thumbs/PORTRAIT-ECA.jpg"
								"projects/thumbs/PORTRAIT-Fwt.jpg"
							]
						}
					}
				) {
					edges {
						node {
							relativePath
							name
							childImageSharp {
								id
								fluid(maxWidth: 632) {
									aspectRatio
									...GatsbyImageSharpFluid_withWebp
								}
							}
						}
					}
				}
			}
		`
	);

	data.getImageAttributes = function (dataKey, relativePath) {
		const imageAttributes = this[dataKey]['edges'].find(
			({ node }) => node.relativePath === relativePath
		).node.childImageSharp;

		return imageAttributes;
	};

	const projectsList = [
		{
			link: '/en/projects/eca-vaud/',
			image: data.getImageAttributes(
				'projectImages',
				'projects/thumbs/PORTRAIT-ECA.jpg'
			),
			title: 'ECA Vaud',
			subtitle: 'UI concept – Design – Web development - Workshops',
		},
		{
			link: '/en/projects/fwt-freeride-world-tour/',
			image: {
				type: 'gif',
				fluid: [
					{
						src: gifFwt,
					},
				],
				// the aspectRatio is automatically set for jpg images coming from `useStaticQuery`, but for GIFs we need to specify it to help with size calculation
				aspectRatio: 496 / 496, // width / height
			},
			title: 'FWT - Freeride World Tour',
			subtitle: 'UI concept - Design - Web development - Workshops',
			linkedProjects: [
				{
					link: '/fr/projets/fwt-freeride-world-tour-branding/',
					title: 'Discover FWT rebranding',
				},
			],
		},
		{
			link: '/en/projects/ackermann-branding/',
			image: data.getImageAttributes(
				'projectImages',
				'projects/thumbs/SQUARE-Ackermann.jpg'
			),
			title: 'Ackermann',
			subtitle: 'Branding – Brand positionning – Packaging',
			linkedProjects: [
				{
					link: '/en/projects/ackermann-typography/',
					title: 'Ackermann handcrafted typography',
				},
			],
		},
		{
			link: '/en/projects/theatre-de-vidy-lausanne/',
			image: data.getImageAttributes(
				'projectImages',
				'projects/thumbs/PORTRAIT-Vidy.jpg'
			),
			title: 'Théâtre de Vidy-Lausanne',
			subtitle: 'UX Design – Digital Design – Web Development',
		},
		{
			link: '/en/projects/ecole-technique-de-la-vallee-de-joux/',
			image: {
				type: 'gif',
				fluid: [
					{
						src: gifEtjf,
					},
				],
				// the aspectRatio is automatically set for jpg images coming from `useStaticQuery`, but for GIFs we need to specify it to help with size calculation
				aspectRatio: 660 / 976, // width / height
			},
			title: 'Ecole Technique de la Vallée de Joux',
			subtitle: 'Brand Identity',
		},
		{
			link: '/en/projects/montreux-jazz-festival/',
			image: data.getImageAttributes(
				'projectImages',
				'projects/thumbs/SQUARE-MJF-Site.jpg'
			),
			title: 'Montreux Jazz Festival',
			subtitle:
				'Brand and Strategy Workshops – Digital Design – Web Development',
		},
		{
			link: '/en/projects/les-chauffeurs-de-remy/',
			image: data.getImageAttributes(
				'projectImages',
				'projects/thumbs/SQUARE-LesChauffeurs.jpg'
			),
			title: 'Les chauffeurs de Rémy',
			subtitle:
				'Brand positionning - Branding - Digital Design - Web Development',
		},
		{
			link: '/en/projects/terre-des-hommes-my-orange/',
			image: data.getImageAttributes(
				'projectImages',
				'projects/thumbs/PORTRAIT-TDH2022.jpg'
			),
			title: 'Terre des Hommes – My Orange',
			subtitle: 'Web Experience - Digital Design',
		},
		{
			link: '/en/projects/crowsnest-properties-branding/',
			image: {
				type: 'gif',
				fluid: [
					{
						src: gifCrowsnestMobile,
					},
					// {
					// 	src: gifCrowsnestDesktop,
					// 	media: '(min-width:768px)',
					// },
				],
				// the aspectRatio is automatically set for jpg images coming from `useStaticQuery`, but for GIFs we need to specify it to help with size calculation
				aspectRatio: 660 / 976, // width / height
			},
			title: 'Crowsnest<br>Properties',
			subtitle: 'Branding – Brand Positioning',
		},
		{
			link: '/en/projects/rachis-clinic/',
			image: data.getImageAttributes(
				'projectImages',
				'projects/thumbs/SQUARE-RachisClinic.jpg'
			),
			title: 'Rachis Clinic',
			subtitle: 'Branding - Brand Positioning',
		},
		{
			link: '/en/projects/montreux-jazz-festival-swipe-app/',
			image: data.getImageAttributes(
				'projectImages',
				'projects/thumbs/SQUARE-MJF-Swipe.jpg'
			),
			title: 'MJF Swipe',
			subtitle:
				'Digital Experience Design and Development – Data Analytics',
		},
		{
			link: '/en/projects/avrion-therapeutics-branding/',
			image: data.getImageAttributes(
				'projectImages',
				'projects/thumbs/PORTRAIT-Avrion.jpg'
			),
			title: 'Avrion Therapeutics',
			subtitle: 'Branding – Brand Positioning',
		},
		{
			link: '/en/projects/debiopharm/',
			image: data.getImageAttributes(
				'projectImages',
				'projects/thumbs/PORTRAIT-Debiopharm.jpg'
			),
			title: 'Debiopharm',
			subtitle: 'Strategy Workshops – Creative Content – Web Development',
			// casestudy: '/fr/todo/',
			// awarded: true,
			// awardedTitle: 'Awarded Project',
		},
		{
			link: 'https://www.behance.net/gallery/72112911/Lillustr-Dynamic-Styleguide',
			image: data.getImageAttributes(
				'projectImages',
				'projects/Illustre-SQUARE.jpg'
			),
			title: 'L’Illustré',
			subtitle: 'Digital design – Dynamic Styleguide Development',
		},
		// {
		// 	link: 'https://www.behance.net/gallery/63809979/Jobtrek',
		// 	image: data.getImageAttributes(
		// 		'projectImages',
		// 		'projects/Jobtrek-PORTRAIT.jpg'
		// 	),
		// 	title: 'Jobtrek Foundation',
		// 	subtitle:
		// 		'Brand and Digital Design – Creative Content – Web Development',
		// },
		// {
		// 	link: 'https://www.behance.net/gallery/55881941/Blancpain-Ocean-Commitment',
		// 	image: data.getImageAttributes(
		// 		'projectImages',
		// 		'projects/Blancpain-OBLONG.jpg'
		// 	),
		// 	title: 'Blancpain Ocean<br>Commitment',
		// 	subtitle:
		// 		'Digital Experience Design and Development – Data Analytics',
		// },
		// {
		// 	link: '/en/projects/hesav-haute-ecole-vaudoise-sante/',
		// 	image: data.getImageAttributes(
		// 		'projectImages',
		// 		'projects/HESAV-SQUARE.jpg'
		// 	),
		// 	title: 'HESAV',
		// 	subtitle:
		// 		'Brand and Strategy Workshops – Digital Design – Web Development',
		// },
		// {
		// 	link: 'https://www.behance.net/gallery/74696635/Azura',
		// 	image: data.getImageAttributes(
		// 		'projectImages',
		// 		'projects/Azura-PORTRAIT.jpg'
		// 	),
		// 	title: 'Azura',
		// 	subtitle:
		// 		'Brand and Digital Design – Creative Content – Web Development',
		// },
		// {
		// 	link: 'https://www.behance.net/gallery/55375731/Tooyoo',
		// 	image: data.getImageAttributes(
		// 		'projectImages',
		// 		'projects/Tooyoo-OBLONG.jpg'
		// 	),
		// 	title: 'Tooyoo',
		// 	subtitle: 'Brand Identity and Motion Design',
		// },
	];

	return (
		<Layout
			language={language}
			pageClass={pageClass}
			footerRelatedLinks={footerRelatedLinks}
			location={props.location}
			translations={[{ uri: '/fr/projets/', lang: 'fr' }]}
			title="Projects - Superhuit"
		>
			<HeroPage image={imgTree} imageX={49} title="Projects" />

			<section className="grid" aria-label="Projects">
				<ListProjects data-animation-page projectsList={projectsList} />
				{/* <Cta
					text="You have a project and you are impatient to start?"
					cta="Hire Us"
					url="/en/contact/"
				/> */}
			</section>

			<section className="grid" aria-label="Send Message">
				<FormMessage
					title="How may we help you?"
					text="Email us at"
					contact="hello@superhuit.ch"
				/>
			</section>
		</Layout>
	);
};

export default ProjectsPage;
